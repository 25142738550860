<template>
    <comp-form title="分类" ref="comp_form" submit-api="/gateway/apps/o2o/api/o2o/admin/updateSupplierCategory" detail-api="/gateway/apps/o2o/api/o2o/admin/getSupplierCategoryByCatId" id-key="catId" :isJson="false" :submit-before="onSubmitBefore" :data-before="onDataBefore" @on-submit="onReset">
        <template v-slot:form-box="data">
            <ElemFormItem title="供应商" name="supplierId" required>
                <Select v-model="data.form.supplierId" filterable style="width:300px">
                    <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                </Select>
            </ElemFormItem>
            <ElemFormItem title="分类名称" name="catName" required>
                <Input style="width:300px" maxlength="100" v-model="data.form.catName" placeholder="请填写分类名称"></Input>
            </ElemFormItem>
            <ElemFormItem title="分类描述">
                <Input style="width:300px" maxlength="255" type="textarea" v-model="data.form.catDesc" placeholder="请填写分类描述"></Input>
            </ElemFormItem>
            <ElemFormItem title="分类图标">
                <ElemUpload :value="data.form.catPic" :concise="true" @on-change="data.form.catPic = $event.value"></ElemUpload>
            </ElemFormItem>
            <ElemFormItem title="排序">
                <Input style="width:300px" type="number" v-model="data.form.sortOrder" placeholder="请填写排序数值（从小到大）"></Input>
            </ElemFormItem>
            <ElemFormItem title="启禁用">
                <i-switch :true-value="1" :false-value="0" :value="data.form.isShow" @on-change="data.form.isShow = $event"></i-switch>
            </ElemFormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../jointly/components/CompForm.vue"
import ElemFormItem from "../../jointly/components/elem-form-item.vue"
import ElemUpload from "../../jointly/components/elem-upload.vue"

export default {
    components: { CompForm, ElemFormItem, ElemUpload },

    data() {
        return {
            // 商家列表
            suppliers: null,
        }
    },

    mounted() {
        this.getSupplierList()
    },

    methods: {
        getSupplierList() {
            this.$get("/gateway/apps/o2o/api/o2o/user/selectUserHasSupplier", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) {
                    this.suppliers = res.dataList
                }
            })
        },

        /**
         * 打开表单窗口
         */
        open(id) {
            this.$refs.comp_form.open(id)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId
            data.oemCode = parent.vue.oemInfo.oemCode
            data.appCode = "shop"

            if (!data.sortOrder) {
                delete data.sortOrder
            }

            if (!data.isShow && data.isShow !== 0) {
                data.isShow = 1
            }

            return data
        },
    },
}
</script>
